@font-face {
  font-family: 'Bauer Bodoni';
  src: url('./assets/fonts/bauer_bodoni_regular.otf');
}

@font-face {
  font-family: 'SpaceMono';
  src: url('./assets/fonts/spacemono_regular.ttf');
}

$base-light: rgb(247, 247, 237);
$link-blue: #007bff;
$get-green: #42bf4c;
$light-gray: #f0f6fa;
$required-red: #e83e8c;
$medium-gray: #848788;
$post-yellow: #ffeb3b;
$patch-blue: #215cc9;
$delete-red: #ff0505;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html,
#root {
  height: 100%;
}

.v-container {
  height: 100%;
}

.app {
  padding-top: 60px;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0px;
}

.logo-text {
  color: black;
  font-size: 36px;
  font-family: 'Bauer Bodoni';
  margin-bottom: 0px;
  font-weight: 600;
  letter-spacing: 2px;
}

.code {
  font-family: 'SpaceMono';
  letter-spacing: 0.5px !important;
}

.light {
  font-weight: normal;
  letter-spacing: normal;
  font-size: 24px;
}

.column {
  height: 100%;
  overflow: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.column.sidebar {
  background-color: $base-light;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: lightgray;
}

.column.content {
  padding: 40px;
}

.sidebar.row {
  width: 100%;
  min-height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
}

.sidebar.row.header {
  color: gray;
  cursor: default;
}

.sidebar.row.link {
  color: black;
  cursor: pointer;
}

.sidebar.row.link.selected {
  color: $link-blue;
  background-color: white;
  border-left-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.content.title {
  font-size: 24px;
  font-weight: 600;
  color: black;
  margin-bottom: 15px;
  white-space: pre-wrap;
}

.content.semi_title {
  font-size: 18px;
  font-weight: 500;
  color: black;
  margin-bottom: 15px;
  white-space: pre-wrap;
}

.content.subtitle {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 300;
  color: dimgrey;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.content.text {
  font-size: 16px;
  color: black;
  white-space: pre-wrap;
  margin-top: 5px;
}

.content.separator {
  height: 1px;
  background-color: lightgray;
  width: 80%;
}

code {
  background-color: $base-light;
  padding: 3px;
}

.endpoint {

  &__wrapper {
    display: block;
  }

  &__title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  &__title {
    margin-left: 10px;
    font-weight: 600;
    font-size: 18px;
  }

  &__description {
    margin-top: 10px;
    font-size: 15px;
  }

  &__request-wrapper {
    border-radius: 10px;
    border-width: 1px;
    padding: 15px;
    border-style: solid;
    border-color: lightgray;
    margin-top: 10px;
  }

  &__request-title {
    font-weight: 700;
  }

  &__request-section {
    padding-top: 10px;
  }

  &__request-section-title {
    color: $medium-gray;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__request-section-wrapper {
    margin-top: 10px;
    background-color: $light-gray;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
  }

  &__request-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &__request-item-key {
    width: 14%;
  }

  &__request-item-level {
    width: 10%;
    font-size: 13px;
  }

  &__request-item-level.required {
    color: $required-red;
  }

  &__request-item-level.optional {
    color: $medium-gray;
  }

  &__request-item-type {
    width: 10%;
  }

  &__request-item-description {
    width: 70%;
    font-size: 14px;
    font-weight: 200;
    color: black;
  }

  &__response-example {
    background-color: $light-gray;
    white-space: pre-wrap;
    font-size: 13px;
    padding: 10px;
    border-radius: 10px;
  }

  &__request-curl-wrapper {
    position: relative;
    margin-top: 10px;
    background-color: $light-gray;
    // padding-top: 5px;
    // padding-bottom: 5px;
    // padding-left: 10px;
    // padding-right: 10px;
    border-radius: 10px;
    padding: 10px;
    font-size: 12px;
    color: #000b5f;
    white-space: pre-wrap;
  }

  &__request-example-button {
    position: absolute !important;
    top: 0px;
    right: 0px;
  }

  &__request-example-collapse {
    min-height: 10px !important;
  }
}

.method {
  border-radius: 10px;
  font-weight: 700;
  font-size: 14px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 6px;
  padding-right: 6px;
  color: white;
}
.method.get {
  background-color: $get-green;
}
.method.post {
  background-color: $post-yellow;
  color: black;
}
.method.patch {
  background-color: $patch-blue;
}
.method.delete {
  background-color: $delete-red;
}

.banners.hover {
  position: fixed;
  right: 10px;
  bottom: 10px;
}